.experience-container{
    padding: 5% 15%;
    background-color: #FAFAFA;
    font-family: 'Poppins', sans-serif;
}


.experience-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    margin: auto;
    display: block;
  }
  
  .ex-col{ 
    margin: 20px auto;
    display: block;
    text-align: center;
  }
  
  .ex-col p{
    margin: auto;
    text-align: center;
    display: block;
    font-size: 20px;
  }
  .ex-col h1{
    margin: 10px auto;
    text-align: center;
    display: block;
    font-size: 45px;
    font-weight: bold;
  }
  .ex-col img{
      width: 140px;
  }

  .col-title-ex{
    text-align: center;
    margin: auto;
    display: block;
  }

  .col-title-ex h1{
    font-size: 5vw;
    font-weight: bold;
  }
  @media (max-width: 768px) and (min-width: 559px) {
    .col-title-ex h1{
      font-size: 7vw;
    }
  }

  @media (max-width: 560px) and (min-width: 300px) {
    .col-title-ex h1{
      font-size: 9vw;
    }
  }

  @media (max-width: 425px) {
    .ex-col img{
      width: 117px;
  }
  }

  @media (max-width: 2800px) and (min-width: 2000px) {
    .ex-col p{
      font-size: 35px;
    }
    .ex-col h1{
      font-size: 60px;
    }
  }


  