.navbar{
    background-color: #002042;
    border-bottom: 1px solid #fff;
}
.navbar-brand{
    width: 43px;
}
 
 .navbar-toggler-icon{
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3e%3cpath stroke='white' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  
}
.navbar-toggler{
    border-color: #002042;
       width: 51px;
       height: 37px;

}
 
.navbar-toggler:focus,
.navbar-toggler:active{
    border-color: transparent;
    outline: none;
    box-shadow: none;
}

 
 
.nav-link{
    color: #fff;
    font-size: 13px;
    text-decoration: none;
    margin: 0px 8px 0px 8px;
}  

.active{
   background-color: #abaaaa17;
   border-radius: 15px;
    width: auto;
    font-weight: bold;
    text-decoration: none;
}

.nav-link:hover{
    background-color: #abaaaa17;
    border-radius: 15px;
    color: #fff;
}

.navbar .container-fluid{
    padding: 3px 50px;
}
.navbar-nav{
    margin-left: 40px;
}

.nav-button{
 font-family: inherit;
 display: inline-block;
 width: 8em;
 height: 2.6em;
 position: relative;
 overflow: hidden;
 border: 1px solid #fff;
 transition: color .5s;
 z-index: 1;
 font-size: 14px;
 border-radius: 20px;
 font-weight: 500;
 color: var(#002042);
}

.nav-button:before{
 content: "";
 position: absolute;
 z-index: -1;
 background:  #FFB703;
 height: 150px;
 width: 200px;
 border-radius: 50%;

}

.nav-button:hover{
    color: #002042;
     border: #FFB703;
}

.nav-button:before {
    top: 100%;
    left: 100%;
    transition: all .7s;
   }
   
   .nav-button:hover:before {
    top: -30px;
    left: -30px;
   }
   
   .nav-button:active:before {
    background: #002042;
    transition: background 0s;
   }

   @media (max-width: 992px) {

    .navbar-nav{
    margin-left: 0px;
}
   }
 
   @media (max-width: 500px) {
    .navbar .container-fluid{
        padding: 3px 15px;
    }
    
   }


   @media (max-width:991px) {
    .nav-button{
        font-size:11px; 
        width: 8.7em;
        height: 2.3em;
    }
   }

   @media (max-width: 2800px) and (min-width: 2560px) {
    .nav-button{
        font-size: 20px;
        width: 200px;
        height: 55px;
       }
       .nav-link{
        font-size: 20px;
        margin: 0px 15px 0px 15px; 
    } 
    .navbar-brand{
        width: 80px;
    }
   }

   @media (max-width: 2559px) and (min-width: 2000px) {
    .nav-button{
        font-size: 17px;
        width: 170px;
        height: 45px;
       }
       .nav-link{
        font-size: 15px;
        margin: 0px 15px 0px 15px; 
    } 
    .navbar-brand{
        width: 55px;
    }
   }

   @media (max-width: 1999px) and (min-width: 1700px) {
    .nav-button{
        font-size: 16px;
        width: 160px;
        height: 40px;
       }
       .nav-link{
        font-size: 14px;
        margin: 0px 15px 0px 15px; 
    } 
    .navbar-brand{
        width: 50px;
    }
   }


    