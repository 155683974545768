.container-fluid-eachProject{
    padding: 5% 7%;
}

.eachproject-mainTitle{
  text-align: center;
  font-weight: bold;
  font-size: 5vw;
  color: #001D42;
  margin: 10px 0px 80px 0px;

}

@media (max-width: 768px) and (min-width: 559px) {
   .eachproject-mainTitle{
     font-size: 7vw;
   }
 }

 @media (max-width: 560px) and (min-width: 300px) {
   .eachproject-mainTitle{
     font-size: 9vw;
   }
 }
.first-section-eachproject{
    margin: 60px 0px 60px 0px;
}


.project-details{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 55px;
    
}

 .details h6 {
    margin: 1px auto 8px auto;
    display: block;
    width: 50%;
    font-weight: 600;
    font-size: 24px;
    color: #001D42;
}

.details p{
    margin: 1px auto 8px auto;
    display: block;
    width: 50%;
    font-weight: 275;
    font-size: 17px;
    color: #989898;
}

@media (max-width: 768px) and (min-width:550px) {
   .project-details{
      padding-right: 0px;
  }
   .details h6 {
      width: 70%;
      font-weight: 600;
      font-size: 18px;
  }
  .details p{
      width: 70%;
      font-size: 15px;
  }
}

@media (max-width: 549px) and (min-width:300px) {
   .project-details{
      padding-right: 0px;
  }
   .details h6 {
      width: 100%;
      font-weight: 600;
      font-size: 15px;
  }
  
  .details p{
      width: 100%;
      font-size: 13px;
  }
}
 
 .image1-eachproject{
    border-radius: 25px;
    width: 640px;
    height: 640px;
    float: right;
    display: block;
 }

 .image2-eachproject{
    border-radius: 25px;
    width: 360px;
    height: 640px;
 }

 

 .image4-eachproject{
    width: 450px;
    height:450px;
     display: block;
     margin: auto;
    border-radius: 25px;
 }
 .image5-eachproject{
   width: 450px;
   height:450px;
    display: block;
    margin: auto;
   border-radius: 25px;
}
 .after-p{
  margin: 30px 0px ;
  font-size: 30px;
  text-align: center;
  font-weight: 500;
  color: #001D42;
 }

 .before-p{
    text-align: center;
    margin: 30px 0px;
    font-size: 30px;
    font-weight: 500;
    color: #001D42;
   }


 .first-p-eachproject{
    font-weight: 500;
    color: #949494;
    width: 61%;
    font-size: 19px;
    margin: 40px 0px 5px 70px;
    display: inline-block;
 }

  
.first-p-eachproject::first-line {
     color: #001D42; 
  }
  

  .eachproject-testimonial-img-details-container .wrap-detais{
    display: inline-block;
   
    margin: auto 20px;
  }

 
  .eachproject-testimonial-img-details-container .wrap-detais p,h6{
    margin-bottom: 0px;
    font-size: 17px;


  }
  
  .eachproject-testimonial-img-details-container img{
    width: 70px;
    background-color: rgb(230, 230, 230);
    border-radius: 50%;
    margin: 0px 5px 20px 0px;
  }

  .eachproject-testimonial-p-start-container p {
     font-size: 19px;
     color: #001D42;
     line-height: 34.2px;
     font-weight: 500;
     margin: 20px 0px 0px 0px;
     width: 80%;
     
     
  }

  .eachproject-testimonial-p-start-container img {
    width: 70px;
 }

  .container-eachproject-testimonial-section{
     padding: 4% 0%;
  }

  .videoReview-eachproject{
    width: 600px;
    height: 600px;
    border-radius: 30px;
    display: block;
    margin: auto;
  }

  .mobileVideo-projectsPages{
   width: 550px;
   height: 590px;
  }

  .container-thirdSection{
   background-color: #FAFAFA;
   border-radius: 10px;
  }
  .div-right-mobile{
   display: block;
   margin: auto;
  }

  .div-right-mobile h1{
   margin-bottom: 40px;
   font-size: 55px; 
   color: #001D42;
   font-weight: bold;
   margin: auto;
   display: block;
  }

  .div-right-mobile p{
   margin-bottom: 40px;
   margin-top: 40px;
   font-size: 18px; 
   width: 80%;
   color: #606060;
   height: 230px;
  }

  .div-right-mobile .btn1-mobile-right{
   border: 1px solid #001D42;
   border-radius: 35px;
   padding: 5px 15px;
   color: #001D42;
   background-color: transparent;
   margin-right: 20px;
  }

  .div-right-mobile .btn2-mobile-right{
   border: 1px solid #001D42;
   border-radius: 35px;
   padding: 5px 15px;
   background-color: #001D42;
   color: #fff;
   margin: auto 20px auto auto;
  }

  .div-right-mobile .btn1-mobile-right:hover{
   border: 1px solid #001D42;
   border-radius: 35px;
   padding: 5px 15px;
   color: #fff;
   background-color: #001D42;
   margin-right: 20px;
  }

  .div-right-mobile .btn2-mobile-right:hover{
   border: 1px solid #001D42;
   border-radius: 35px;
   padding: 5px 15px;
   background-color: transparent;
   color: #001D42;
   margin-right: 20px;
  }

  .container-fluid-eachProject-mdmVoiceovers{
   padding: 5% 5%;
  }

 
  .pizzaAD-projects{
    width: 800px;
    height: 100%;
    display: block;
    
    margin: 20px auto;
    border-radius: 25px;
  }
  .pizzavid-container{
   width: 100%;
  }
  .pizzavid-container p{
   font-weight: 500;
   color: #949494;
   width: 800px;
   font-size: 19px;
   margin:  auto;
   display: block;
  }

  .pizzavid-container p::first-line {
    color: #001D42; 
 }

 .row-voiceovers{
   margin: 40px auto;
 }

  .voiceOvers1-projects{
      width: 400px;
      margin:   auto;
      display: block;
      float: right;
      border-radius: 25px;
  }

  .voiceOvers2-projects{
   width: 400px;
   margin: auto;
   display: block;
   float: left;
   border-radius: 25px;
}

/* mdm voice overs responisve */
@media (max-width:930px)  and (min-width:768px){
   .pizzaAD-projects{
      width: 690px;
    }
    .pizzavid-container p{
     width: 690px;
     font-size: 17px;
    }

    .voiceOvers1-projects{
      width: 330px;
      border-radius: 20px;
  }

  .voiceOvers2-projects{
   width: 330px;
   border-radius: 20px;
}
}


@media (max-width:767px)  and (min-width:630px){
   .pizzaAD-projects{
      width: 560px;
    }
     
    .pizzavid-container p{
     width:560px;
     font-size: 15px;
    }
    .voiceOvers1-projects{
      width: 260px;
      border-radius: 20px;
  }

  .voiceOvers2-projects{
   width:260px;
   border-radius: 20px;
}
}

@media (max-width:629px)  and (min-width:500px){
   .pizzaAD-projects{
      width: 450px;
      
    }
     
    .pizzavid-container p{
     width:450px;
     font-size: 14px;
    }
    .voiceOvers1-projects{
      width: 190px;
      border-radius: 20px;
  }

  .voiceOvers2-projects{
   width:190px;
   border-radius: 20px;
}
}
 
@media (max-width:499px)  and (min-width:425px){
   .pizzaAD-projects{
      width: 380px;
      
    }
     
    .pizzavid-container p{
     width:380px;
     font-size: 13px;
    }
    .voiceOvers1-projects{
      width: 170px;
      border-radius: 20px;
  }

  .voiceOvers2-projects{
   width:170px;
   border-radius: 20px;
}
}
@media (max-width:424px)  and (min-width:300px){
   .pizzaAD-projects{
      width: 280px;
      
    }
     
    .pizzavid-container p{
     width:280px;
     font-size: 11px;
    }
    .voiceOvers1-projects{
      width: 140px;
      border-radius: 20px;
  }

  .voiceOvers2-projects{
   width:140px;
   border-radius: 20px;
}
}
 


/* responsive  */
/* vidwo review */
@media (max-width:1260px) and (min-width:1070px) {
   .videoReview-eachproject{
      width: 500px;
      height: 500px;
   }
   .image1-eachproject{
      border-radius: 25px;
      width: 640px;
      height: 640px;
      float:none;
      display: block;
   }
   .image2-eachproject{
      border-radius: 25px;
      width: 360px;
      height: 640px;
      display: block;
      margin: auto;
   }
   .eachproject-testimonial-p-start-container p {
      /* padding-right: 220px; */
      font-size: 17px;
      color: #001D42;
      line-height: 34.2px;
      font-weight: 500;
      margin: 20px 0px 0px 0px;
      width: 80%;
   }
}

@media (max-width:1069px) and (min-width:850px) {
   .videoReview-eachproject{
      width: 400px;
      height: 400px;
   }
   .eachproject-testimonial-p-start-container p {
      /* padding-right: 220px; */
      font-size: 15px;
      color: #001D42;
      line-height: normal;
      font-weight: 500;
      margin: 20px 0px 0px 0px;
      width: 80%;
      
      
   }
}


@media (max-width:849px) and (min-width:768px) {
   .videoReview-eachproject{
      width: 350px;
      height: 350px;
   }
   .eachproject-testimonial-p-start-container p {
      /* padding-right: 220px; */
      font-size: 14px;
      color: #001D42;
      line-height: normal;
      font-weight: 500;
      margin: 20px 0px 0px 0px;
      width: 80%;
      
      
   }
}

@media (max-width:767px) and (min-width:500px) {
   .videoReview-eachproject{
      width: 400px;
      height: 400px;
      margin: 30px auto;
      border-radius: 15px;
   }
   .eachproject-testimonial-p-start-container p {
      /* padding-right: 220px; */
      font-size: 13px;
      color: #001D42;
      line-height:normal;
      font-weight: 500;
      margin: 20px 0px 0px 0px;
      width: 80%;
      
   }
   .eachproject-testimonial-img-details-container{
      margin-left: 40px;
   }
   .eachproject-testimonial-p-start-container{
      margin-left: 40px;

   }
}

@media (max-width:499px) and (min-width:425px) {
   .videoReview-eachproject{
      width: 350px;
      height: 350px;
      margin: 30px auto;
      border-radius: 15px;
   }
   .eachproject-testimonial-p-start-container p {
      /* padding-right: 220px; */
      font-size: 13px;
      color: #001D42;
      line-height: normal;
      font-weight: 500;
      margin: 0px 0px 0px 0px;
      width: 80%;
      
   }
   .eachproject-testimonial-img-details-container{
      margin-left: 40px;
   }
   .eachproject-testimonial-p-start-container{
      margin-left: 40px;

   }
}

@media (max-width:424px) and (min-width:300px) {
   .videoReview-eachproject{
      width: 250px;
      height: 250px;
      margin: 30px auto;
      border-radius: 15px;
   }
   .eachproject-testimonial-p-start-container p {
      /* padding-right: 220px; */
      font-size: 12px;
      color: #001D42;
      line-height: normal;
      font-weight: 500;
      margin: 0px 0px 0px 0px;
      width: 87%;
   }
   .eachproject-testimonial-img-details-container{
      margin-left: 15px;
   }
   .eachproject-testimonial-p-start-container{
      margin-left: 15px;
   }
   .eachproject-testimonial-img-details-container img{
      width: 60px;
      background-color: rgb(230, 230, 230);
      border-radius: 50%;
      margin: 0px 0px 20px 0px;
    }
    .eachproject-testimonial-img-details-container .wrap-detais p, h6 {
      margin-bottom: 0px;
      font-size: 15px;
  }
}

/* vidwo1 and reel seciton */

@media (max-width: 1215px) and (min-width: 1020px) {
   .image1-eachproject{
      border-radius: 25px;
      width: 540px;
      height: 540px;
      /* margin: auto; */
      float:none;
      display: block;
   }
   .image2-eachproject{
      border-radius: 25px;
      width: 304px;
      height: 540px;
      margin: auto;
      display: block;
  
   }
}

@media (max-width: 1019px) and (min-width: 800px) {
   .image1-eachproject{
      border-radius: 25px;
      width: 420px;
      height: 420px;
      /* margin: auto; */
      /* float:none; */
      display: block;
   }
   .image2-eachproject{
      border-radius: 25px;
      width: 236px;
      height:420px;
      margin: auto;
      display: block;
  
   }
   .first-p-eachproject{
      font-weight: 500;
      color: #949494;
      width: 90%;
      font-size: 19px;
      margin: 40px 0px 5px 20px;
      display: inline-block;
      /* background-color: yellow; */
   }
}


@media (max-width:799px) and (min-width: 768px) {
   .image1-eachproject{
      border-radius: 25px;
      width: 340px;
      height: 340px;
      /* margin: auto; */
      /* float:none; */
      display: block;
   }
   .image2-eachproject{
      border-radius: 25px;
      width: 191px;
      height:340px;
      margin: auto;
      display: block;
   }
   .first-p-eachproject{
      font-weight: 500;
      color: #949494;
      width: 100%;
      font-size: 17px;
      margin: 40px 0px 5px 20px;
      display: inline-block;
      /* background-color: yellow; */
   }
   .first-section-eachproject{
      margin: 20px 0px 60px 0px;
  }
}

@media (max-width: 767px) and (min-width: 600px) {
   .image1-eachproject{
      border-radius: 25px;
      width: 440px;
      height: 440px;
      margin:  25px  auto;
      float:none;
      display: block;
   }
   .image2-eachproject{
      border-radius: 25px;
      width: 304px;
      height:540px;
      margin: auto;
      display: block;
   }
   .first-p-eachproject{
      font-weight: 500;
      color: #949494;
      width: 100%;
      font-size: 15px;
      margin: 25px auto;
      display: block;
      /* background-color: yellow; */
   }
   .first-section-eachproject{
      margin: 10px 0px 60px 0px;
  }
}

@media (max-width: 599px) and (min-width: 400px) {
   .image1-eachproject{
      border-radius: 25px;
      width: 350px;
      height: 350px;
      margin:  25px  auto;
      float:none;
      display: block;
   }
   .image2-eachproject{
      border-radius: 25px;
      width: 225px;
      height:400px;
      margin: auto;
      display: block;
   }
   .first-p-eachproject{
      font-weight: 500;
      color: #949494;
      width: 80%;
      font-size: 13px;
      margin: 25px auto;
      display: block;
      /* background-color: yellow; */
   }
   .first-section-eachproject{
      margin: 7px 0px 60px 0px;
  }
}
@media (max-width: 399px) and (min-width: 300px) {
   .image1-eachproject{
      border-radius: 25px;
      width: 275px;
      height: 275px;
      margin:  25px  auto;
      float:none;
      display: block;
   }
   .image2-eachproject{
      border-radius: 25px;
      width: 225px;
      height:400px;
      margin: auto;
      display: block;
   }
   .first-p-eachproject{
      font-weight: 500;
      color: #949494;
      width: 80%;
      font-size: 11px;
      margin: 25px auto;
      display: block;
      /* background-color: yellow; */
   }
   .first-section-eachproject{
      margin: 0px 0px 60px 0px;
  }
}


/* befor after seciton */
@media (max-width: 1100px) and (min-width: 900px){
   .image4-eachproject{
      width: 380px;
      height:380px;
       /* float: right; */
       display: block;
       margin: auto;
      border-radius: 15px;
   }
   .image5-eachproject{
     width: 380px;
     height:380px;
      /* float: left; */
      display: block;
      margin: auto;
      border-radius: 15px;
  }
}
@media (max-width: 899px) and (min-width: 700px){
   .image4-eachproject{
      width: 300px;
      height:300px;
       /* float: right; */
       display: block;
       margin: auto;
       border-radius: 15px;
   }
   .image5-eachproject{
     width: 300px;
     height:300px;
      /* float: left; */
      display: block;
      margin: auto;
      border-radius: 15px;
  }
  .after-p{
   /* text-align: right; */
   margin: 30px 0px ;
   /* padding-left: 110px; */
   /* width: 70%; */
   font-size: 25px;
   text-align: center;
   font-weight: 500;
   color: #001D42;
  }
 
  .before-p{
     text-align: center;
     margin: 30px 0px;
     
     /* padding-right: 110px; */
     /* width: 70%; */
     font-size: 25px;
     font-weight: 500;
     color: #001D42;
    }
}

@media (max-width: 699px) and (min-width: 450px){
   .image4-eachproject{
      width: 200px;
      height:200px;
       /* float: right; */
       display: block;
       margin: auto;
       border-radius: 15px;
   }
   .image5-eachproject{
     width: 200px;
     height:200px;
      /* float: left; */
      display: block;
      margin: auto;
      border-radius: 15px;
  }
  .after-p{
   font-size: 20px;
  }
 
  .before-p{
     font-size: 20px;
    }
}
@media (max-width: 449px) and (min-width: 375px){
   .image4-eachproject{
      width: 150px;
      height:150px;
       /* float: right; */
       display: block;
       margin: auto;
       border-radius: 15px;
   }
   .image5-eachproject{
     width: 150px;
     height:150px;
      /* float: left; */
      display: block;
      margin: auto;
      border-radius: 15px;
  }
  .after-p{
   font-size: 15px;
  }
 
  .before-p{
     font-size: 15px;
    }
}
@media (max-width: 374px) and (min-width: 300px){
   .image4-eachproject{
      width: 130px;
      height:130px;
       /* float: right; */
       display: block;
       margin: auto;
      border-radius: 15px;
   }
   .image5-eachproject{
     width: 130px;
     height:130px;
      /* float: left; */
      display: block;
      margin: auto;
     border-radius: 15px;
  }
  .after-p{
   font-size: 15px;
  }
 
  .before-p{
     font-size: 15px;
    }
}



/* mobile seciton */
@media (max-width: 1140px) and (min-width: 900px) {
    
      .mobileVideo-projectsPages{
         width: 500px;
         /* width: 100%; */
         height: 540px;
         /* object-fit: cover; */
   }
}
 

@media (max-width: 1280px) and (min-width:900px) {
   .mobileVideo-projectsPages{
      width: 450px;
      /* width: 100%; */
      height: 490px;
      display: block;
      margin: auto;
      /* object-fit: cover; */
}
.div-right-mobile h1{
   margin-bottom: 30px;
   font-size: 40px; 
  }

  .div-right-mobile p{
   margin-bottom: 0px;
   margin-top: 0px;
   font-size: 16px; 
   height: 250px;
   width: 380px;
  }
  .div-right-mobile .btn1-mobile-right{
   margin-right: 3px;
   font-size: 13px;
   margin-top: 20px;
  }

  .div-right-mobile .btn2-mobile-right{
   margin-right: 10px;
   font-size: 13px;
  }
}

@media (max-width:899px) and (min-width: 768px) {
    
   .mobileVideo-projectsPages{
      width: 390px;
      /* width: 100%; */
      height: 430px;
      /* object-fit: cover; */
      display: block;
      margin: auto;
}

.div-right-mobile h1{
   margin-bottom: 20px;
   font-size: 35px; 
  }

  .div-right-mobile p{
   margin-bottom: 0px;
   margin-top: 0px;
   font-size: 14px; 
   height: 260px;
   width: 330px;
  }

  .div-right-mobile .btn1-mobile-right{
   margin-right: 3px;
   font-size: 13px;
   margin-top: 0px;
  }

  .div-right-mobile .btn2-mobile-right{
   margin-right: 10px;
   font-size: 13px;
  }
}

@media (max-width:767px) and (min-width: 500px) {
    
   .mobileVideo-projectsPages{
      width: 400px;
      /* width: 100%; */
      height: 440px;
      margin: auto;
      display: block;
      /* object-fit: cover; */
}
  .div-right-mobile{
    margin-left: 20px;
}
.div-right-mobile h1{
   margin-top: 40px;
   margin-bottom: 20px;
   font-size: 30px; 
  }

  .div-right-mobile p{
   margin-top: 0px;
   margin-bottom: 0px;
   font-size: 16px; 
   /* height: 255px; */
   width: 410px;
  }

  .div-right-mobile .btn1-mobile-right{
   margin-right: 3px;
   font-size: 10px;
   margin-top: 10px;
   margin-bottom: 20px;
   /* padding: 3px; */
  }

  .div-right-mobile .btn2-mobile-right{
   margin-right: 0px;
   font-size: 10px;
   /* padding: 4px; */
  }
}

@media (max-width:499px) and (min-width: 400px) {
    
   .mobileVideo-projectsPages{
      width: 360px;
      /* width: 100%; */
      height: 400px;
      margin: auto;
      display: block;
      /* object-fit: cover; */
}
.div-right-mobile{
   margin-left: 30px;
}
.div-right-mobile h1{
   margin-top: 20px;
   margin-bottom: 20px;
   font-size: 30px; 
  }

  .div-right-mobile p{
   margin-bottom: 0px;
   margin-top:  0px;
   font-size: 12px;
   height: 180px;
   width: 300px;
  }

  .div-right-mobile .btn1-mobile-right{
   margin-right: 3px;
   font-size: 10px;
   /* padding: 3px; */
  }

  .div-right-mobile .btn2-mobile-right{
   margin-right: 0px;
   font-size: 10px;
   /* padding: 4px; */
  }
}

@media (max-width:399px) and (min-width: 300px) {
    
   .mobileVideo-projectsPages{
      width: 290px;
      /* width: 100%; */
      height: 330px;
      margin: auto;
      display: block;
      /* object-fit: cover; */
}
.div-right-mobile{
   margin-left: 15px;
}
 
.div-right-mobile h1{
  margin-top: 30px;
   margin-bottom: 10px;
   font-size: 20px; 
  }

  .div-right-mobile p{
   margin-bottom: 0px;
   margin-top: 0px;
   font-size: 12px; 
   width: 240px;
   height: 220px;
    
  }

  .div-right-mobile .btn1-mobile-right{
   margin-right: 3px;
   font-size: 9px;
   /* margin-top: 20%; */
   /* padding: 3px; */
  }

  .div-right-mobile .btn2-mobile-right{
   margin-right: 0px;
   font-size: 9px;
   /* padding: 4px; */
  }
}




 .vimeo-container {
   width: 360px;
   height: 640px;
   border-radius: 20px;
   display: block;
   margin: auto;
   padding: 0px;
   /* margin: 20px; */
 }
 .inner-vimeo-reel{
   width: 360px;
   height: 640px;
   overflow: hidden;
   border-radius: 20px;
position: relative;
 }



 @media (max-width: 1215px) and (min-width: 1020px) {
   .vimeo-container{
      border-radius: 20px;
      width: 304px;
      height: 540px;
      margin: auto;
      display: block;
      /* object-fit: cover; */
   }
   .inner-vimeo-reel{
      border-radius: 20px;
       
      width: 100%;
      height: 100%;
      margin: auto;
      display: block;
    
   }

 
}

 

@media (max-width: 1019px) and (min-width: 800px) {
 
   .vimeo-container{
      border-radius: 0px;
      width: 236px;
      height:420px;
      margin: auto;
      display: block;
      /* object-fit: cover; */
   }
   .inner-vimeo-reel{
      border-radius: 0px;
       
      width: 100%;
      height: 100%;
      margin: auto;
      display: block;
    
   }
}


@media (max-width:799px) and (min-width: 768px) {
   .vimeo-container{
      border-radius: 0px;
      width: 191px;
      height:340px;
      margin: auto;
      display: block;
      /* object-fit: cover; */
   }
   .inner-vimeo-reel{
      border-radius: 0px;
       
      width: 100%;
      height: 100%;
      margin: auto;
      display: block;
    
   }
   
}

/* @media (max-width: 767px) and (min-width: 600px) {
   .image1-eachproject{
      border-radius: 25px;
      width: 440px;
      height: 440px;
      margin:  25px  auto;
      float:none;
      display: block;
   }
   .image2-eachproject{
      border-radius: 25px;
      width: 304px;
      height:540px;
      margin: auto;
      display: block;
   }
   .first-p-eachproject{
      font-weight: 500;
      color: #949494;
      width: 100%;
      font-size: 15px;
      margin: 25px auto;
      display: block;
  
   }
   .first-section-eachproject{
      margin: 10px 0px 60px 0px;
  }
} */

@media (max-width: 530px) and (min-width:425px) {
   
   .vimeo-container{
      border-radius:20px;
      width: 300px;
      height:533px;
      margin: auto;
      display: block;
      /* object-fit: cover; */
   }
   .inner-vimeo-reel{
      border-radius: 20px;
      width: 100%;
      height: 100%;
      margin: auto;
      display: block;
    
   }
   
}

@media (max-width: 424px) and (min-width:300px) {
   
   .vimeo-container{
      border-radius:20px;
      width: 260px;
      height:462px;
      margin: auto;
      display: block;
      /* object-fit: cover; */
   }
   .inner-vimeo-reel{
      border-radius: 20px;
      width: 100%;
      height: 100%;
      margin: auto;
      display: block;
    
   }
   
}
/* @media (max-width: 399px) and (min-width: 300px) {
   .image1-eachproject{
      border-radius: 25px;
      width: 275px;
      height: 275px;
      margin:  25px  auto;
      float:none;
      display: block;
   }
   .image2-eachproject{
      border-radius: 25px;
      width: 225px;
      height:400px;
      margin: auto;
      display: block;
   }
   .first-p-eachproject{
      font-weight: 500;
      color: #949494;
      width: 80%;
      font-size: 11px;
      margin: 25px auto;
      display: block;
     
   }
   .first-section-eachproject{
      margin: 0px 0px 60px 0px;
  }
} */


 /* @media (max-width: 425px) {
   .vimeo-container {
      width: 360px;
      height: 640px;
      border-radius: 20px;
      display: block;
      margin: auto;
      padding: 0px;
     
    }
 } */
 


 @media (max-width: 2800px) and (min-width: 2500px) {
   
 .image1-eachproject{
   width: 1040px;
   height: 1040px;
   margin: auto;
   float:none;
   display: block;
}
.vimeo-container {
   width: 585px;
   height: 1040px;
   display: block;
   margin: auto;
 }
 .inner-vimeo-reel{
   width: 585px;
   height: 1040px;
 }

 .details h6 {
   width: 50%;
   font-size: 35px;
}

.details p{
   width: 50%;
   font-size: 30px;
}
.project-details{
   padding-right: 70px;
   padding-left: 70px;
}
.first-p-eachproject{
   width: 61%;
   font-size: 35px;
   margin: 40px 0px 5px 110px;
}
.image4-eachproject{
   width: 750px;
   height:750px;
}
.image5-eachproject{
  width: 750px;
  height:750px;
}
.after-p{
 font-size: 60px;
}

.before-p{
   font-size: 60px;
  }
  .mobileVideo-projectsPages{
   width: 850px;
   height: 890px;
   display: block;
   margin: auto;
  }

  .div-right-mobile{
   display: block;
   margin: auto;
  }

  .div-right-mobile h1{
   margin-bottom: 60px;
   font-size: 65px; 
  }

  .div-right-mobile p{
   margin-bottom: 0px;
   margin-top: 0px;
   font-size: 35px; 
   height: 470px;
  }

  .div-right-mobile .btn1-mobile-right{
   padding: 15px 35px;
   margin-right: 30px;
   margin-top: 30px;
   font-size: 25px;
  }

  .div-right-mobile .btn2-mobile-right{
   padding: 15px 35px;
   font-size: 25px;
  }

  .eachproject-testimonial-img-details-container img{
   width: 150px;
 }
 .eachproject-testimonial-img-details-container .wrap-detais p, h6 {
   font-size: 25px;
}
 
.eachproject-testimonial-p-start-container p {
   font-size: 30px;
   color: #001D42;
   line-height: normal;
   font-weight: 500;
   margin: 0px 0px 0px 0px;
   width: 87%;
}
.eachproject-testimonial-p-start-container img{
   margin-top: 30px;
   width: 150px;
}
.videoReview-eachproject{
   width: 850px;
   height: 850px;
}

 }


 @media (max-width: 2499px) and (min-width: 2000px) {
   
   .image1-eachproject{
     width: 940px;
     height: 940px;
     margin: auto;
     float:none;
     display: block;
  }
  .first-p-eachproject{
   width: 61%;
   font-size: 25px;
   margin: 40px 0px 5px 80px;
}
  .vimeo-container {
     width: 529px;
     height: 940px;
     display: block;
     margin: auto;
   }
   .inner-vimeo-reel{
     width: 529px;
     height: 940px;
   }
  
   .details h6 {
     width: 50%;
     font-size: 35px;
  }
  
  .details p{
     width: 50%;
     font-size: 30px;
  }
  .project-details{
     padding-right: 50px;
     padding-left: 50px;
  }
  .image4-eachproject{
     width: 650px;
     height:650px;
  }
  .image5-eachproject{
    width: 650px;
    height:650px;
  }
  .after-p{
   font-size: 50px;
  }
  .before-p{
     font-size: 50px;
    }
    .mobileVideo-projectsPages{
     width: 750px;
     height: 790px;
     display: block;
     margin: auto;
    }
  
    .div-right-mobile{
     display: block;
     margin: auto;
    }
  
    .div-right-mobile h1{
     margin-bottom: 60px;
     font-size: 60px; 
    }
  
    .div-right-mobile p{
     margin-bottom: 0px;
     margin-top: 0px;
     font-size: 25px; 
     height: 400px;
    }
  
    .div-right-mobile .btn1-mobile-right{
     padding: 10px 30px;
     margin-right: 30px;
     margin-top: 10px;
     font-size: 25px;
    }
  
    .div-right-mobile .btn2-mobile-right{
     padding: 10px 30px;
     font-size: 25px;
    }
  
    .eachproject-testimonial-img-details-container img{
     width: 140px;
   }
   .eachproject-testimonial-img-details-container .wrap-detais p, h6 {
     font-size: 20px;
  }
   
  .eachproject-testimonial-p-start-container p {
     font-size: 25px;
  }
  .eachproject-testimonial-p-start-container img{
     margin-top: 30px;
     width: 130px;
  }
  .videoReview-eachproject{
     width: 750px;
     height: 750px;
  }
  
   }

   @media (max-width: 1999px) and (min-width: 1600px) {
   
      .image1-eachproject{
        width: 740px;
        height: 740px;
        margin: auto;
        float:none;
        display: block;
     }
     .first-p-eachproject{
      width: 61%;
      font-size: 20px;
      margin: 40px 0px 5px 80px;
   }
     .vimeo-container {
        width: 416px;
        height: 740px;
        display: block;
        margin: auto;
      }
      .inner-vimeo-reel{
        width: 416px;
        height: 740px;
      }
     
      .details h6 {
        width: 50%;
        font-size: 30px;
     }
     
     .details p{
        width: 50%;
        font-size: 25px;
     }
     .project-details{
        padding-right: 50px;
        padding-left: 50px;
     }
     .image4-eachproject{
        width: 550px;
        height:550px;
     }
     .image5-eachproject{
       width: 550px;
       height:550px;
     }
     .after-p{
      font-size: 40px;
     }
     .before-p{
        font-size: 40px;
       }
       .mobileVideo-projectsPages{
        width: 650px;
        height: 690px;
        display: block;
        margin: auto;
       }
     
       .div-right-mobile h1{
        margin-bottom: 60px;
        font-size: 50px; 
       }
     
       .div-right-mobile p{
        margin-bottom: 0px;
        margin-top: 0px;
        font-size: 21px; 
        height: 300px;
       }
     
       .div-right-mobile .btn1-mobile-right{
        padding: 8px 25px;
        margin-right: 30px;
        margin-top: 10px;
        font-size: 15px;
       }
     
       .div-right-mobile .btn2-mobile-right{
        padding: 8px 25px;
        font-size: 15px;
       }
     
       .eachproject-testimonial-img-details-container img{
        width: 120px;
      }
      .eachproject-testimonial-img-details-container .wrap-detais p, h6 {
        font-size: 20px;
     }
      
     .eachproject-testimonial-p-start-container p {
        font-size: 20px;
     }
     .eachproject-testimonial-p-start-container img{
        margin-top: 30px;
        width: 120px;
     }
     .videoReview-eachproject{
        width: 700px;
        height: 700px;
     }
     
      }
  
 