.about-section-sm{
    padding: 7% 6%;
    display: none;
}

.titel-heading-about{
    font-size: 5vw;
    font-weight: 850;
    color: #002042;
    text-align: center;
    margin-bottom: 30px;

}
@media (max-width: 768px) and (min-width: 559px) {
    .titel-heading-about{
      font-size: 7vw;
    }
  }

  @media (max-width: 560px) and (min-width: 300px) {
    .titel-heading-about{
      font-size: 9vw;
    }
  }
.outer-div-sm-about{
    width: 300px;
    margin: auto;
    display: block;
}

.carousel-slider-about{
 text-align: center;
 width: 300px;
 margin: auto;
 display: block;
 
}

.carousel-slider-about img{
     width: 240px;
     margin: auto;
     display: block;
}

@media (max-width:700px) {
    .about-section-sm{
        display: block;   
    }
}
 
@media (max-width:500px) {
    .carousel-slider-about img{
        width: 200px;
   }
}
 