.stars{
    display: inline-block;
}

.stars-container img{
    width: 100px;
    padding: 5px 15px;
}

.container-fluid-clientsTestimonials{
    padding: 2% 10%  10% 10%;
}

.container-fluid-clientsTestimonials h1{
    text-align: center;
    font-weight: bold;
    color: #002042;
    margin: 0px 0px 50px 0px;
    font-size: 5vw;
}

.col-text-star{
    margin: auto;
    display: block;
    padding: 1px 0px;
}

.clinet-text-container{
    padding: 5px 70px 3px 15px;
}
.clinet-text-container p{
   color: #002042; 
   font-weight: 500;
   line-height: 25px;
}

@media (max-width:500px) {
    .clinet-text-container p{
        color: #002042; 
        font-weight: 500;
        line-height: normal;
        font-size: 13px;
     }
}


.col-client-img-name h6{
    text-align: center;
    margin: 0px auto 10px auto;
    display: block;
    width: 70%;
    font-weight:500;
    font-size: 14px;
}

.col-client-img-name p{
    text-align: center;
    margin: 10px auto 0px auto;
    display: block;
    width: 70%;
    font-weight:500;
}

.col-client-img-name img{
    width: 130px;
    margin: auto;
    display: block;
    background-color: rgb(213, 210, 210);
    border-radius: 50%;
}



@media (max-width:767px) {
    .stars-container img{
        margin: auto;
        display: block;
    }
    .col-text-star{
        text-align: center;   
    }

    .clinet-text-container{
        padding: 5px 30px 3px 30px;
        font-size: 15px;
    }

}


@media (max-width: 768px) and (min-width: 559px) {
    .container-fluid-clientsTestimonials h1{
      font-size: 7vw;
    }
  }
  
  @media (max-width: 560px) and (min-width: 300px) {
    .container-fluid-clientsTestimonials h1{
      font-size: 9vw;
    }
  }

  @media (max-width:2800px) and (min-width: 2200px) {
    .col-client-img-name h6{
        font-size: 25px;
    }
    
    .col-client-img-name p{
        font-size: 25px;
    }
    
    .col-client-img-name img{
        width: 160px;
    }
    .stars-container img{
        width: 150px;
    }
    .clinet-text-container p {
        color: #002042;
        font-weight: 500;
        line-height: normal;
        font-size: 30px;
    }
  }