.tidycal-container {
    margin-top: 50px;
    position: relative;
    height: 100vh;
    overflow: hidden;
    padding-top: 56.25%;  
  }
  
  .tidycal-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }