body{
    margin: 0;
    box-sizing: border-box;
    overflow-x: hidden;
    overflow-y: scroll;
    scroll-behavior: smooth;
     
}

.section {
    height: 100vh; /* Set the height of each section as desired */
    scroll-snap-align: start; /* Ensure each section aligns to the start of the viewport */
  }
  