.checkbox-button {
    display: inline-block;
    background-color: #fff;
    padding: 4px 20px;
    border-radius:20px;
    border: 1px solid #000;
    cursor: pointer;
    margin: 10px 8px;
  }

  .checkbox-button:hover{
    background-color: #faf9f9;
    color: #002042;
}
  
  .checkbox-button.checked {
    background-color: #FFB703;
    color: #fff;
  }
 
  .container-fluid-contactform{
    padding: 5% 7%;
    font-family: 'Poppins', sans-serif;
  }
  
  .form-card-container{
    box-shadow: rgba(0, 0, 0, 0.15) 3px -3px 3px -2px, rgba(0, 0, 0, 0.15) 0px 3px 3px 0px;;
    border-radius: 65px;
    padding: 6% 6%;
  }

  .form-card-container h1{
    font-weight: 700;
    color: #002042;
    font-size: 70px;
    margin-bottom: 65px;
  }

  .form-card-container p{
    font-weight: 700;
    color: #002042;
    margin: 10px;
  }

  .img-roadmap-1{
   width: 300px;
   margin: auto;
   display: block;
  }
  .img-roadmap-2{
    display: none;
  }

 
  .input-container {
    position: relative;
    margin: 50px 20px;
    width: 80%;
  }
  
  .input-container input[type="text"], input[type="email"]{
    font-size: 14px;
    width: 100%;
    border: none;
    border-bottom: 2px solid #a7a6a6;
    padding: 5px 0;
    background-color: transparent;
    outline: none;
  }
  
  .input-container .label {
    position: absolute;
    top: 0;
    left: 0;
    color: #a7a6a6;
    transition: all 0.3s ease;
    pointer-events: none;
  }
  
  .input-container input[type="text"]:focus ~ .label, 
  .input-container input[type="text"]:valid ~ .label,
  .input-container input[type="email"]:focus ~ .label, 
  .input-container input[type="email"]:valid ~ .label {
    top: -20px;
    font-size: 16px;
    color: #002042;
  }
  
  .input-container .underline {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 2px;
    width: 100%;
    background-color: #002042;
    transform: scaleX(0);
    transition: all 0.3s ease;
  }
  
  .input-container input[type="text"]:focus ~ .underline,  
  .input-container input[type="text"]:valid ~ .underline,
  .input-container input[type="email"]:focus ~ .underline,  
  .input-container input[type="email"]:valid ~ .underline {
    transform: scaleX(1);
  }
  


  .PhoneInputInput{
    color: #a7a6a6;
    border: none;
    border-bottom: 2px solid #a7a6a6;
    margin: 50px 20px;
    width: 100%;
  }
   
  .PhoneInputInput:focus{
    outline: none;
    color: #002042;
    font-size: 16px;
    border-bottom: 2px solid #002042;
  }
  
/* textarea styling */
.input-container-textarea  {
  position: relative;
  margin: 50px 0px;
  width: 100%;
}

.input-container-textarea textarea {
  font-size: 14px;
  width: 100%;
  border: none;
  border-bottom: 2px solid #ccc;
  padding: 0px 0;
  background-color: transparent;
  outline: none;
  resize: none;
}

.input-container-textarea .label {
  position: absolute;
  top: 0;
  left: 0;
  color: #ccc;
  transition: all 0.3s ease;
  pointer-events: none;
  margin:5px 0px 0px 0px;
   
}

.input-container-textarea textarea:focus ~ .label,
.input-container-textarea  textarea:not(:focus):valid ~ .label {
  top: -20px;
  font-size: 14px;
  color: #333;
   margin:0px 0px 0px 0px;
  
}

.input-container-textarea  .underline {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 2px;
  width: 100%;
  background-color: #333;
  transform: scaleX(0);
  transition: all 0.3s ease;
}

.input-container-textarea textarea:focus ~ .underline,
.input-container-textarea textarea:not(:focus):valid ~ .underline {
  transform: scaleX(1);
  margin-bottom: 5px
}


.button-form{
  border-radius: 20px;
  width: 150px;
  height: 40px;
  border: 1px solid #000;
  background-color: #fff;
}

.button-form:hover{
  border: 1px solid #fff;
  color: #fff;
  background-color: #FFB703;
}

/* responsive  */
@media (max-width: 1024px) and (min-width:768px) {
  .checkbox-button {
  font-size: 13px;
}
.form-card-container h1{
  font-size: 55px;
  margin-bottom: 45px;
}
}

@media (max-width:768px) and (min-width: 648px) {
  .form-card-container h1{
    font-size: 65px;
    margin-bottom: 45px;
  }
}
@media (max-width:647px) and (min-width: 598px) {
  .form-card-container h1{
    font-size: 60px;
    margin-bottom: 40px;
  }
}
@media (max-width:597px) and (min-width: 548px) {
  .form-card-container h1{
    font-size: 55px;
    margin-bottom: 35px;
  }
}

@media (max-width:547px) and (min-width: 425px) {
  .form-card-container h1{
    font-size: 40px;
    margin-bottom: 30px;
  }
}
@media (max-width:424px) and (min-width: 320px) {
  .form-card-container h1{
    font-size: 30px;
    margin-bottom: 20px;
  }
}


@media (max-width: 767px) and (min-width:425px) {
  .checkbox-button {
  font-size: 13px;
  padding: 2px 10px;
  margin: 8px 5px;
}
.services-container{
  margin:10px 5px 20px 5px;
}

.input-container {
  margin: 25px 25px;
}
.button-form{
  width: 130px;
  height: 30px;
}
 
}

@media (max-width: 424px) and (min-width:300px) {
  .checkbox-button {
  font-size: 11px;
  padding: 4px 10px;
  margin: 6px 4px;
}
.services-container{
  margin:10px 5px 20px 5px;
}

.input-container {
  margin: 20px 20px;
}
.button-form{
  width: 130px;
  height: 30px;
}
.form-card-container h1{
  font-size: 25px;
 margin-bottom: 15px;
}
}


@media (max-width:991px) {
  .img-roadmap-1{
    display: none; 
  }
  .img-roadmap-2{
    display: block; 
  }
}
 
@media (max-width: 991px) and (min-width: 710px){
  .img-roadmap-2{
    display: block;
    transform: rotate(-0deg);
    width: 600px;
    margin: 0px auto 40px auto;
     
  }
}

@media (max-width: 709px) and (min-width: 500px){
  .img-roadmap-2{
    display: block;
    transform: rotate(-0deg);
    width: 450px;
    margin: 0px auto 40px auto;
     
  }
}


@media (max-width: 499px) and (min-width: 400px){
  .img-roadmap-2{
    display: block;
    transform: rotate(-0deg);
    width: 370px;
    margin: 0px auto 40px auto;
     
  }
}

@media (max-width: 399px) and (min-width: 300px){
  .img-roadmap-2{
    display: block;
    transform: rotate(-0deg);
    width: 275px;
    margin: 0px auto 40px auto;
     
  }
}

@media (max-width: 555px) {
  .form-card-container{
    border-radius: 38px;
    padding: 6% 6%;
  }
  .button-form{
    border-radius: 20px;
    width: 100px;
    height: 25px;
    font-size: 14px;
    border: 1px solid #000;
    background-color: #fff;
    margin-bottom: 15px;
  }
  .form-card-container h1{
     
    margin-top: 15px;
  }
}


@media (max-width: 2800px) and (min-width: 2560px) {
 
  .button-form{
    border-radius: 25px;
    width: 200px;
    height: 50px;
    font-size: 25px;
  }
  .form-card-container h1{
    margin-top: 15px;
    font-size: 90px;
  }
  .container-fluid-contactform{
    padding: 5% 10%;
  }
  .form-card-container{
    border-radius: 38px;
    padding: 6% 10%;
  }
  .checkbox-button {
    display: inline-block;
    background-color: #fff;
    padding: 8px 40px;
    border-radius:25px;
    font-size: 25px;
    border: 1px solid #000;
    cursor: pointer;
    margin: 20px 16px;
  }
  .input-container-textarea .label {
    position: absolute;
    top: 0;
    left: 0;
    color: #ccc;
    transition: all 0.3s ease;
    pointer-events: none;
    margin:5px 0px 0px 0px;
    font-size: 30px; 
  }
  .input-container .label {
    font-size: 30px; 
     padding-bottom: 20px;
  }
  .input-container input[type="text"], input[type="email"]{
    padding: 25px 0;
  }
  .input-container-textarea textarea {
    padding: 25px 0;
}

.PhoneInputInput{
  padding: 16px 0px ;
  font-size: 30px;
}
.services-container p{
  
  font-size: 35px;
}
.services-container{
  margin:10px 5px 40px 5px;
  
}
.input-container input[type="text"]:focus ~ .label, 
.input-container input[type="text"]:valid ~ .label,
.input-container input[type="email"]:focus ~ .label, 
.input-container input[type="email"]:valid ~ .label {
  top: -30px;
  font-size: 30px;
  color: #002042;
}
.img-roadmap-1{
  width: 550px;
}
.PhoneInputInput:focus{
  font-size: 30px;
  border-bottom: 2px solid #002042;
}
}



@media (max-width: 2561px) and (min-width: 2000px) {
  .button-form{
    border-radius: 25px;
    width: 200px;
    height: 50px;
    font-size: 25px;
  }
  .form-card-container h1{
    margin-top: 15px;
    font-size: 80px;
  }
  .container-fluid-contactform{
    padding: 5% 8%;
  }
  .form-card-container{
    border-radius: 38px;
    padding: 6% 8%;
  }
  .checkbox-button {
    padding: 6px 25px;
    border-radius:25px;
    font-size: 25px;
    margin: 20px 16px;
  }
  .input-container-textarea .label {
    position: absolute;
    top: 0;
    left: 0;
    color: #ccc;
    transition: all 0.3s ease;
    pointer-events: none;
    margin:5px 0px 0px 0px;
    font-size: 25px; 
  }
  .input-container .label {
    font-size: 25px; 
     padding-bottom: 20px;
  }
  .input-container input[type="text"], input[type="email"]{
    padding: 15px 0;
  }
  .input-container-textarea textarea {
    padding: 15px 0;
}

.PhoneInputInput{
  padding: 5px 0px ;
  font-size: 25px;
}
.services-container p{
  font-size: 30px;
}
.services-container{
  margin:10px 5px 40px 5px;
  
}
.input-container input[type="text"]:focus ~ .label, 
.input-container input[type="text"]:valid ~ .label,
.input-container input[type="email"]:focus ~ .label, 
.input-container input[type="email"]:valid ~ .label {
  top: -30px;
  font-size: 25px;
  color: #002042;
}
.input-container-textarea textarea:focus ~ .label,
.input-container-textarea  textarea:not(:focus):valid ~ .label {
  top: -20px;
  font-size: 25px;
  color: #333;
   margin:0px 0px 0px 0px;
  
}

.img-roadmap-1{
  width: 450px;
}
.PhoneInputInput:focus{
  font-size: 25px;
  border-bottom: 2px solid #002042;
}
 
}