body{
    margin: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
}
.landing-home{
  width: 100%;  
  margin-right: 0px;
  background-color: #002042;
  padding-bottom: 10px;
   
}

.container-fluid-landinghome{
    padding: 3% 5%;
 }

.landing-img{
    width: 500px;
    margin: auto;    
    display: block;
    margin-top: 30px;
}


.span-instaincon-homelanding{
    text-align: center;
    width: 70%;
}

.heading-col-home {
    color: #fff;
    font-size: 5.3vw;
    font-weight: bold;
    margin: auto;    
    display: block;
    
    height: 100%;
 
}
.playicon, .meetingicon{
    margin-right: 4px;
}


.landing-buttons-container{
    display: inline-block;
    width: 100%;
}
 

.landing-buttons-container .button1{
    border-radius: 20px;
    background-color: #8cb8e6;
    color: #002042;
    border: 1px solid #002042;
    padding: 5px 0px;
    width: 160px;
    display: inline-block;
    
}
.landing-buttons-container .button1:hover{
    background-color: #fff;
    border: 1px solid #fff;
    color: #002042;
}

.landing-buttons-container .button2{
    border-radius: 20px;
    background-color: #002042;
    color: #fff;
    border: 1px solid #fff;
    padding: 5px 0px;
    width: 160px;
    margin-left: 20px;
    display: inline-block;
    /* margin: 3px 68px; */
}
.landing-buttons-container .button2:hover{
    background-color: #FFB703; 
    color: #fff;
    border: 1px solid #FFB703;
}
 

.col-insta-link{
   text-align: center;
   cursor: pointer;
}
.span-instaincon-homelanding p:hover{
 text-decoration: underline;
}

.success-bg-img{
    color: #fff;
    background-image: url("/public/images/bg-yellow-success.png");
    background-size: contain;  
    background-repeat: no-repeat;
    padding: 0px 60px 10px 40px;
}

@media (max-width: 2800px) and (min-width: 2200px) {
    .landing-img{
        width: 900px;
    
    }
    .landing-buttons-container .button1{
        padding: 10px 0px;
        width: 230px;
        font-size: 20px;
    }
    .landing-buttons-container .button2{
        padding: 10px 0px;
        width: 230px;
        font-size: 20px;
    }
    .span-instaincon-homelanding{
       font-size: 23px;
    }

}

@media (max-width: 2199px) and (min-width: 1700px) {
    .landing-img{
        width: 600px;
    }
    .landing-buttons-container .button1{
        padding: 10px 0px;
        width: 200px;
        font-size: 17px;
    }
    .landing-buttons-container .button2{
        padding: 10px 0px;
        width: 200px;
        font-size: 17px;
    }
    .span-instaincon-homelanding{
       font-size: 23px;
    }
    .success-bg-img{
        padding: 0px 50px 0px 40px;
    }

}

@media (max-width: 768px) and (min-width: 540px)  {
    .success-bg-img{
        padding: 0px 30px 10px 30px;
    }
}

@media (max-width: 539px) and (min-width:425px) {
    .success-bg-img{
        padding: 0px 20px 10px 20px;
    }
}

@media (max-width: 424px) and (min-width:300px) {
   .success-bg-img{
    padding: 0px 15px 10px 15px;
}
}

@media (max-width: 767px) {
   .col-insta-link{
    margin-top: 20px;
   }
   .heading-col-home{
    text-align: center;
   }
}

 @media (min-width: 768px ) and (max-width:1024px ) {
    .landing-img{
        width: 370px;
    }
 
  .landing-buttons-container{
    display: flex;
}
.heading-col-home{
    font-size: 5vw;
    margin-top: 65px;
}
    
 }



 @media (min-width:600px) and (max-width:767px) {
    .landing-img{
        width: 300px;
    }
    .landing-buttons-container .button1{
        font-size: 15px;
        width: 125px;
        margin-left: 0px;
   }
   .landing-buttons-container .button2{
       font-size: 14px;
       width: 130px;   
  }
  .landing-buttons-container{
    display: flex;
    justify-content: center;
}
.heading-col-home{
    font-size: 5vw;
    margin-top: 30px;
     
}
     
 }




 @media (min-width:300px) and (max-width:599px) {
    .landing-img{
        width: 230px;
    }

    .landing-buttons-container .button1{
        font-size: 11px;
        width: 105px;
        margin-left: 0px;
   }
   .landing-buttons-container .button2{
       font-size: 10px;
       width: 110px;
      
        
  }
  .landing-buttons-container{
    display: flex;
    justify-content: center;
}

 
 
 }