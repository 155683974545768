.footer-bottom{
    border-top: 0.5px solid #c4c4c4;
    background-color: #002042;
    text-align: left;
}

.outer-footer-container{
  font-family: 'Poppins', sans-serif;
}

.footer-up{
    background-color: #002042;
    padding: 0px 8%;

}

.btn-footer{
    border-radius: 25px;
    width: 130px;
    font-size: 13px;
    padding: 5px 1px;
    color: #fff;
    border: 1px solid #fff;
    background-color: #002042;
}

.btn-footer:hover{
  border-radius: 25px;
   color: #002042;
  background-color: #fff;
  border: 1px solid #fff;
}
.logo-footer{
    width: 40px;
}

.footer-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .icons-container {
    display: flex;
    gap: 10px;  
  }
  
  .btn-footer {
    margin-left: 10px;  
  }

  .copy-right{
    font-size: 20px;
  }
  .backTop{
    color: #ffff;
  }
  .backTop:hover{
    cursor: pointer;
    color: #FFB703;
  }

  .footer-icons{
    color: #ffff;
    cursor: pointer;
  }
  .footer-icons:hover{
    color: #b6b6b6;
  }
  .tiktok-icon{
    background-color: #fff;
    padding:0.8px  1.5px;
    border-radius: 2px;
    color: #002042;
    margin: auto;
    display: block;
  }
  .tiktok-icon:hover{
    background-color: #b6b6b6;;
    color: #002042;
  }
  
  @media (max-width: 660px){
    .backTop{
      display:none;
    }
    .copy-right{
      font-size: 13px;
    }
  }

  @media (max-width: 500px) and (min-width: 425px){
    .footer-icons{
      width: 18px;
    }
    .tiktok-icon{
      font-size: 18px;
      padding:0px  0.3px;
      margin: auto;
      display: inline;
    }
    .backTop{
      display:none;
    }
    .btn-footer{
      width: 95px;
      font-size: 9px;
  }
  .footer-up{
    background-color: #002042;
    padding: 0px 0%;
}
.icons-container {
  display: flex;
  gap: 5px;  
}
.logo-footer{
  width: 30px;
}
.copy-right{
  font-size: 13px;
}

  }



  @media (max-width: 424px) and (min-width: 300px){
    .footer-icons{
      width: 15px;
    }
    .tiktok-icon{
      font-size: 15px;
      padding:0px  0.3px;
      margin: auto;
      display: inline;
    }
    .backTop{
      display:none;
    }
    .btn-footer{
      width: 95px;
      font-size: 9px;
  }
  .footer-up{
    background-color: #002042;
    padding: 0px 0%;
}
.icons-container {
  display: flex;
  gap: 5px;  
}
.logo-footer{
  width: 30px;
  margin-right: 10px;
}
.copy-right{
  font-size: 13px;
}

  }


  @media (max-width: 2800px) and (min-width: 2100px) {
    .logo-footer{
      width: 65px;
    }  
    .copy-right{
      font-size: 25px;
    }
    .icons-container {
      display: flex;
      gap: 10px;  
    }
    .footer-icons{
      width: 40px;
      height: 40px;
    }
    
    .backTop{
    width: 60px;
    height: 60px;
    }
    .btn-footer{
      width: 200px;
      height: 50px;
      font-size: 20px;
  }

  .footer-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
     
  }
  }