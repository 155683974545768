.bg-preloader{
  width: 100%;
  background-color: #f7f7f7;
  opacity: 1;
  animation: fadeOut 4s ease 1s forwards;
}


@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}


  .loader {
    position: relative;
    width: 300px;
    height: 100vh;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: poppins;
    
  }
  
  .loader div {
    position: absolute;
    width: 20px;
    height: 30px;
    border-radius: 5px;
    animation: loader_51899 1.5s ease-in-out infinite;
    display: flex;  
    align-items: center;  
    font-family: 'Poppins', sans-serif;
}
  
  .loader .bar1 {
    left: 0px;
    animation-delay: 0s;
    font-weight:bold;
    font-size:30px;
    color:#002042;
  }
  
  .loader .bar2 {
    left: 30px;
    animation-delay: 0.15s;
    color:#002042;
    font-weight:bold;
    font-size:30px;
  }
  
  .loader .bar3 {
    left: 43px;
    animation-delay: 0.3s;
    color:#002042;
    font-weight:bold;
    font-size:30px;
  }
  
  .loader .bar4 {
    left: 64px;
    animation-delay: 0.45s;
    color:#002042;
    font-weight:bold;
    font-size:30px;
  }
  
  .loader .bar5 {
    left: 85px;
    animation-delay: 0.6s;
    color:#002042;
    font-weight:bold;
    font-size:30px;
  }
  
  .loader .bar6 {
    left: 107px;
    animation-delay: 0.75s;
    color:#002042;
    font-weight:bold;
    font-size:30px;
  }
  
  .loader .bar7 {
    left: 130px;
    animation-delay: 0.9s;
    color:#002042;
    font-weight:bold;
    font-size:30px;
  }
  
  .loader .bar8 {
    left: 150px;
    animation-delay: 1.05s;
    color:#002042;
    font-weight:bold;
    font-size:30px;
  }
  
  .loader .bar9 {
    left: 185px;
    animation-delay: 1.2s;
    color:#002042;
    font-weight:bold;
    font-size:30px;
  }
  .loader .bar10 {
    left: 214px;
    animation-delay: 1.35s;
    color:#002042;
    font-weight:bold;
    font-size:30px;
  }
  .loader .bar11 {
    left: 234px;
    animation-delay: 1.5s;
    color:#002042;
    font-weight:bold;
    font-size:30px;
  }
  .loader .bar12 {
    left: 255px;
    animation-delay: 1.65s;
    color:#002042;
    font-weight:bold;
    font-size:30px;
  }
  
  .loader .bar13 {
    left: 267px;
    animation-delay: 1.8s;
    color:#002042;
    font-weight:bold;
    font-size:30px;
  }
   
  
  @keyframes loader_51899 {
    0% {
      height: 30px;
      transform: translate(0, 0);
    }
  
    50% {
      height: 70px;
      transform: translate(0, 35px);
    }
  
    100% {
      height: 30px;
      transform: translate(0, 0);
    }
  }

 

  