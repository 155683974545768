 

.logoAutoPlay-container {
  margin: 20px 0px 0px 0px;
    background-color: #002042;
    padding: 10px 0px;
    height: 100px;
  }
  
/* logoCarousel.css */

  .logo-container {
    margin: 10px 0px;
    padding: 0px;
    margin: auto;
    display: block;
    height: 100%;
  }
  .logo1 {
    margin: auto;
    display: block;
    width: 235px;
    height: 60px;
    padding: 0px 0px;
    margin-top: 10px;
  }
  .logo2 {
    margin: auto;
    display: block;
    width: 135px;
    height: 60px;
    padding: 0px 0px;
    margin-top: 10px;
  }
  .logo3 {
    margin: auto;
    display: block;
    width: 171px;
    height: 65px;
    padding: 0px 0px;
   margin-top: 10px;
  }
  .logo4 {
    margin: auto;
    display: block;
    width: 208px;
    height: 55px;
    padding: 0px 0px;
    margin-top: 10px;
  }
  .logo5 {
    margin: auto;
    display: block;
    width: 118px;
    height: 65px;
    padding: 0px 0px;
    margin-top: 10px;
  }
  .logo6 {
    margin: auto;
    display: block;
    width: 142px;
    height: 60px;
    padding: 0px 0px;
    margin-top: 10px;
  }
  .logo7 {
    margin: auto;
    display: block;
    width: 132px;
    height: 60px;
    padding: 0px 0px;
    margin-top: 10px;
  }
  .logo8 {
    margin: auto;
    display: block;
    width: 201px;
    height: 55px;
    padding: 0px 0px;
    margin-top: 10px;
  }
  
  .logo9 {
    margin: auto;
    display: block;
    width: 86px;
    height:85px;
    padding: 0px 0px;
  }

  .logo10{
    margin: auto;
    display: block;
    padding:0px 0px;
    width: 167px;
    height: 80px;
  }
  .logo11{
    margin: auto;
    display: block;
    padding:0px 0px;
    width: 155px;
    height:69px;
  }
  
 
  
  