.first-about, .third-about{
    margin: 15% 0% 40% 0%;   
}
.first-about, .second-about{
    float: right;
}

.third-about, .fourth-about{
    float: left;
}


.fifth-about, .fourth-about, .third-about, .second-about, .first-about{
    width: 300px;
}
 
.fifth-about{
  margin-top: 3%;
  margin-left: 0px;
}

.row-fifth-about{
    display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0px;
  margin-left: 0px;
  padding-left: 0px;
  padding-right: 0px;

}
.container-fluid-about{
    padding: 4% 10%;
    font-family: 'Poppins', sans-serif;
}

.about-section .title-heading{
    text-align: center;
    color: #002042;
    font-weight: 800;
    font-size: 5vw;
    margin-bottom: 30px;
}

@media (max-width: 768px) and (min-width: 559px) {
    .about-section .title-heading{
      font-size: 7vw;
    }
  }
  
  @media (max-width: 560px) and (min-width: 300px) {
    .about-section .title-heading{
      font-size: 9vw;
    }
  }

.title-about{
    font-weight: 700;
    color: #002042;
}

.text-about{
    color: #002042;
}

.img-about{
    width: 300px;
    margin: auto;
    display: block;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.imag-about-container{
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (max-width:2800px) and (min-width:2600px) {
    .img-about{
        width: 570px;  
    }
    .first-about, .third-about{
        margin: 30% 0px 70% 0px;   
    }
    .fifth-about{
        margin-left: 0px;
      }
    .title-about{
       font-size: 37px;
    }
    
    .text-about{
        font-size: 35px;
    }
    .fifth-about, .fourth-about, .third-about, .second-about, .first-about{
        width: 630px;
    }
}

@media (max-width:2599px) and (min-width:2201px) {
    .img-about{
        width: 530px;
    }
    .first-about, .third-about{
        margin: 30% 0px 55% 0px;   
    }
    .fifth-about{
        margin-left: 0px;
      }
    .title-about{
       font-size: 30px;
    }
    
    .text-about{
        font-size: 25px;
    }
    .fifth-about, .fourth-about, .third-about, .second-about, .first-about{
        width: 570px;
    }
}


@media (max-width:2200px) and (min-width:1700px) {
    .img-about{
        width: 400px;
    }
    .first-about, .third-about{
        margin: 30% 0px 70% 0px;   
    }
    .fifth-about{
        /* margin-top: 3%; */
        margin-left: 0px;
      }
    .title-about{
       font-size: 20px;
    }
    
    .text-about{
        font-size: 20px;
    }
    .fifth-about, .fourth-about, .third-about, .second-about, .first-about{
        width: 400px;
    }
    
}

 

@media (max-width:1211px) and (min-width:769px) {
    .img-about{
        width: 260px;
    }
}


@media (max-width:850px) and (min-width: 701px) {
    .img-about{
        width: 100%;
        margin: auto;
        display: block;
    }
    .fifth-about, .fourth-about, .third-about, .second-about, .first-about{
        width: 180px;
        margin-left: 20px;
        font-size: 13px;
    }
    .container-fluid-about{
        padding: 4% 5%;
    }
    .about-section .titel-heading{
        font-size: 40px;
    }
    .first-about, .third-about{
        margin: 30px 0px 10px 20px;   
    }
    .fifth-about{
        margin-top: 10px;
        font-size: 13px;
        width: 200px;
       text-align: center;
      }
}
 


@media (max-width:1130px) and (min-width: 851px) {
    .img-about{
        width: 100%;
        margin: auto;
        display: block;
    }
    .fifth-about, .fourth-about, .third-about, .second-about, .first-about{
        width: 220px;
        margin-left: 20px;
        font-size: 16px;
    }
    .container-fluid-about{
        padding: 4% 7%;
    }
    .about-section .titel-heading{
        font-size: 40px;
    }
    .first-about, .third-about{
        margin: 60px 0px 25px 20px;   
    }
    .fifth-about{
        margin-top: 10px;
        font-size: 16px;
        width: 220px;
       text-align: center;
      }
}

 

@media (max-width: 700px) {
    .about-section{
        display: none;
    }
}









