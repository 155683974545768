
  .work-section-container-fluid {
    padding: 5% 15% 7% 15%;
    background-color: #fafafa;
  }
  .work-slide {
    width: 360px !important;
    height: 640px;
    background-color: rgb(162, 159, 159);
    color: azure;
    border-radius: 20px;
    margin: 5px 20px;
  }
  .work-slide video {
    width: 100%;
    height: 640px;
    border-radius: 20px;
    object-fit: cover; 
  }

  @media (max-width:600px) and (min-width:460px) {
    .work-section-container-fluid {
      padding: 5% 10% 5% 10%;
    }
    .work-slide {
      width: 330px !important;
      height: 587px;
      background-color: rgb(162, 159, 159);
      color: azure;
      border-radius: 20px;
    }
    .work-slide video{
      width: 330px !important;
      height: 587px;
    }
  }

  @media (max-width:459px) and (min-width:425px) {
    .work-section-container-fluid {
      padding: 5% 10% 5% 10%;
    }
    .work-slide {
      width: 300px !important;
      height: 533px;
      background-color: rgb(162, 159, 159);
      color: azure;
      border-radius: 20px;
    }
    .work-slide video{
      width: 300px !important;
      height: 533px;
    }
  }

  @media (max-width:424px) and (min-width:376px) {
    .work-section-container-fluid {
      padding: 5% 10% 5% 10%;
    }
    .work-slide {
      width: 270px !important;
      height: 480px;
      background-color: rgb(162, 159, 159);
      color: azure;
      border-radius: 20px;
    }
    .work-slide video{
      width: 270px !important;
      height: 480px;
    }
  }

  @media (max-width:375px) and (min-width:300px) {
    .work-section-container-fluid {
      padding: 5% 7% 5% 7%;
    }
    .work-slide {
      width: 250px !important;
      height: 444px;
      background-color: rgb(162, 159, 159);
      color: azure;
      border-radius: 20px;
    }
    .work-slide video{
      width: 250px !important;
      height: 444px;
    }
  }


  @media (max-width:1120px) and (min-width:899px) {
    .work-section-container-fluid {
      padding: 5% 4% 5% 4%;
    }
    .work-slide {
      width: 360px !important;
      height: 640px;
      background-color: rgb(162, 159, 159);
      color: azure;
      border-radius: 20px;
    }
    .work-slide video{
      width: 360px !important;
      height: 640px;
    }
  }
  @media (max-width:900px) and (min-width:768px) {
    .work-section-container-fluid {
      padding: 5% 2% 5% 2%;
      margin-right: 25px;
    }
    .work-slide {
      width: 340px !important;
      height: 604px;
      background-color: rgb(162, 159, 159);
      color: azure;
      border-radius: 20px;
    } 
    .work-slide video{
      width: 340px !important;
      height: 604px;
    }
     

  }

  
  .work-section-container-fluid .slick-slide {
    display: flex !important;
    justify-content: center;
  }
  .work-section-container-fluid h2{
    font-size: 4.6vw;
    color: #002042;
    font-weight: bold;
  }
 
  

 .button-work{
  border-radius: 20px;
  width: 9em;
  height: 2.3em;
  font-size: 13px;
  color: #fff;
  background-color: #002042;
  border: none;
  position: relative;
  overflow: hidden;
  z-index: 1;
  margin-bottom: 30px;
 }
 .button-work:hover  {
  font-weight: bold;
 color: #002042; 
 border: 1px solid #002042;
 
} 
.button-work::before {
  content: '';
  width: 0;
  height: 2.3em;
  border-radius: 20px;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #fff;
  transition: .5s ease;
  display: block;
  z-index: -1;
}
.button-work:hover::before {
  width: 9em;
} 

.work-section-container-fluid h5{
  color: #FFB703;
}


@media (max-width:461px) {
  .work-section-container-fluid h2{
    font-size: 7.5vw;
  }
 
  .button-work{
    width: 10em;
    height: 2.7em;
    font-size: 10px;
  }

}

@media (max-width: 2800px) and (min-width: 2560px) {
  .work-slide {
    width: 560px !important;
    height: 996px;
    background-color: rgb(162, 159, 159);
    color: azure;
    border-radius: 20px;
  } 
  .work-slide video{
    width: 560px !important;
    height: 996px;
  }
  .button-work{
    border-radius: 25px;
    width: 12em;
    height: 3em;
    font-size: 17px;
   }
   .work-section-container-fluid h5{
    color: #FFB703;
    font-size: 35px;
  }
}

@media (max-width: 2559px) and (min-width: 2000px) {
  .work-slide {
    width: 460px !important;
    height: 818px;
    background-color: rgb(162, 159, 159);
    color: azure;
    border-radius: 20px;
  } 
  .work-slide video{
    width: 460px !important;
    height: 818px;
  }
  .button-work{
    border-radius: 25px;
    width: 11em;
    height: 2.8em;
    font-size: 16px;
   }
   .work-section-container-fluid h5{
    color: #FFB703;
    font-size: 35px;
  }
}