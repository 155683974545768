.projects-container{
    padding: 3% 3%;
   margin: 0 auto;
  }

  .outer-projects .slick-list,
  .outer-projects .slick-slider,
  .outer-projects .slick-track {
    padding: 1.5rem 0;
  }
  .slide-box {
    position: relative;
  height: 100%;
  margin: 20px 0.5rem;
  border-radius: 5px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  border-radius: 20px;
  border: 1px solid #032142;
  
  }
  
  .center .slick-center .slide-box{
     transform: scale(1.3);
    z-index: 10000;
    transition:  all 400ms ease-in-out;
    pointer-events: all;
    opacity: 1;
  }

  .clientsTestimonial-container{
    background-color: #FAFAFA;
  }


  .disc {
    position: absolute;
    right: 0;
    left: 0;
    bottom: -10rem;
    padding: 0.5rem;
    z-index: 1;
    transition: all 400ms ease-in-out;
}
 

 

.disc h2,
.disc p,
.disc a {
    position: relative;
}

.disc h2 {
    font-size: 30px;
    color: #ffff;
}

.disc p {
    width: 90%;
    font-size: 0.8rem;
    color: #000;
}

.disc a {
    margin-left: 0.4rem;
    font-weight: bold;
    color: #FFB703;
}
   
  
  .slide-box img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 20px;
    left: 0;
    transition: all 0.16s ease-in-out;
  }
 

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0);
    transition: background 0.5s ease;
  }

  .slide-box:hover .overlay {
    display: block;
    background: rgba(0, 0, 0, .6);
  }

  .btn-showMore{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    opacity: 0;
    transition: opacity 0.35s ease;
    background-color: #00000000;
    display: block;
    margin: auto;
    padding: 5px 20px;
    color: white;
    border: solid 1px white;
    z-index: 1;
     border-radius: 15px;
     transition: all 0.16s ease-in-out;
  }


  .btn-showMore:hover{
    transform: translate(-50%, -50%) scale(1.03);
  }

  .btn-showMore p {
    font-size: 10px;
    display: block;
    margin: auto;
  }

  .slide-box:hover .btn-showMore {
    opacity: 1;
  }

  @media (max-width: 2800px) and (min-width: 2500px) {
    .btn-showMore{
    padding: 10px 40px;
    }
  .btn-showMore p {
    font-size: 20px;
  }
  }
  
  .center .slide-box{
    opacity: 0.7;
    pointer-events: none;
  }
   
  .bg-blue-michael{
    color: #002042;
    background-image: url("/public/images/bg-blue.png");
    background-size: contain;  
    background-repeat: no-repeat;
    padding: 0px 40px 15px 0px;
  }
  
  .outer-projects h1{
    font-weight: bold;
    color:#002042;
    width: 100%;
    font-size: 5vw;
    text-align: center;
    margin-bottom: 2%;
  }

  .img-each{
    width: 200px;
  }
  
  @media (max-width: 990px) {
    .center .slick-center .slide-box{
      transform: scale(1);
    }
    .center .slide-box{
      opacity: 1;
      pointer-events: all;
    }
  }
  .featured-projects-container{
    padding: 6% 0% 4% 0%;
  }
  .featured-projects-container img{
    width: 100%;
    height: 700px;
    margin: auto;
    display: block;
  }
  .featured-projects-container h1{
  text-align: center;
   margin: 0px auto 5% auto;
   display: block;
   font-weight: bold;
   color: #002042;
   font-size: 5vw
  }

  .slick-prev:before, .slick-prev:focus:before, .slick-next:before, .slick-next:focus:before {
    opacity: 1;
    color:#002042;
}

.mdm-evolutionVideo{
  display:block;
  margin: auto;
  border-radius:20px;
  width: 1100px ;
  height: 714px;
}

/* mdm vidwo responsive */

@media (max-width:1165px) and (min-width: 1024px){
  .mdm-evolutionVideo{
    width: 900px;
    height: 584px ;
  }
}

@media (max-width:1023px) and (min-width: 768px){
  .mdm-evolutionVideo{
    width: 700px;
    height: 454px ;
  }
}

@media (max-width:767px) and (min-width: 525px){
  .mdm-evolutionVideo{
    width: 500px;
    height: 324px ;
  }
}

@media (max-width:524px) and (min-width: 425px){
  .mdm-evolutionVideo{
    width: 400px;
    height: 259px ;
  }
}

@media (max-width:424px) and (min-width: 300px){
  .mdm-evolutionVideo{
    width: 290px;
    height: 195px ;
  }
}

/* title font responsive */
@media (max-width: 768px) and (min-width: 559px) {
  .outer-projects h1{
    font-size: 7vw;
  }
}

@media (max-width: 560px) and (min-width: 300px) {
  .outer-projects h1{
    font-size: 9vw;
  }
}

@media (max-width: 768px) and (min-width: 559px) {
  .featured-projects-container h1{
    font-size: 7vw;
  }
}

@media (max-width: 560px) and (min-width: 300px) {
  .featured-projects-container h1{
    font-size: 9vw;
  }
}


@media (max-width: 2800px) and (min-width: 1582px) {
  .outer-projects .slick-list, .outer-projects .slick-slider, .outer-projects .slick-track {
    padding: 5rem 0;
}  
.slick-prev:before, .slick-prev:focus:before, .slick-next:before, .slick-next:focus:before {
   font-size: 30px;
}
.outer-projects h1 {
  margin-bottom: 0%;
}
}
 